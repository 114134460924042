import { AssetType } from "@iventis/domain-model/model/assetType";
import { TitleWithIcon } from "@iventis/styles/src/components/title-with-icon";
import { Content } from "@iventis/translations/content/typed-content";
import { CursorTooltip, LoadingComponent, incrementalInputWidth, incrementalValueSelectorComponentCreator, imageInputComponentCreator } from "@iventis/components";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { InteractiveElement, borderRadius, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useContext, useMemo, useState, PropsWithChildren } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useAssetSignatureCache, useFunctionality } from "@iventis/utilities";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { smallIconButtonSize } from "@iventis/styles/src/atomic-rules";
import { Model } from "@iventis/domain-model/model/model";
import { ModelLayerStyle } from "@iventis/map-engine/src/types/models";
import { validateModelCanHaveCustomImage } from "@iventis/model-utilities";
import { EditStyleItemComponent } from "./edit-style-item";
import StyleContainer from "./style-container";
import { StyledStyleItemContainer } from "./line-edit-style";
import { EditStyleProps } from "./style.types";
import { useModelSelector } from "./edit-style.helpers";
import { EditStyleContext } from "./edit-style-context";
import { ModelAttributeStyles, ModelAttributeBasedModalStatus } from "./model-attribute-styles";
import { FormulaSummaryComponent } from "./edit-style-formula-summary";

const variableSizeProps = {
    minValue: 0,
    maxValue: 1000,
    increment: 1,
    units: [{ id: "meters", name: "" }],
    selectedUnitId: "meters",
    decimals: 2,
    showUnit: false,
};

export interface ModelWithThumbnail extends Model {
    thumbnailUrl: string;
    metadata?: { customImage?: boolean };
}

const ModelEditStyleComponent: FunctionComponent<PropsWithChildren<EditStyleProps<ModelLayerStyle>>> = ({ layerStyle, changeStyleValue, dataFields, children }) => {
    const translate = useIventisTranslate();
    const functionality = useFunctionality();
    const theme = useTheme<Theme>();

    const imageGetter = useAssetSignatureCache();

    const { assetService, getModels, onAssetRefresh } = useContext(EditStyleContext);

    const [attributeBasedModal, setAttributeBasedModal] = useState<ModelAttributeBasedModalStatus>("hidden");

    const { selectedModels, thumbnailAssetIds, modelSelector, changeModelThumbnail } = useModelSelector(
        layerStyle,
        (id, model) => {
            changeStyleValue("model", createStaticStyleValue(id));
            if (model.height != null && layerStyle.height.extractionMethod !== StyleValueExtractionMethod.Mapped) {
                changeStyleValue("height", createStaticStyleValue(model.height));
                changeStyleValue("length", createStaticStyleValue(model.length));
                changeStyleValue("width", createStaticStyleValue(model.width));
            }
        },
        getModels,
        dataFields,
        () => handleRemoveAttributeBasedStyling(true),
        () => setAttributeBasedModal("model")
    );

    const isModelVariableSize = useMemo(() => selectedModels != null && !selectedModels.some((model) => model?.height == null || model?.height === 0), [selectedModels]);

    const isModelUsingCustomImage = useMemo(() => selectedModels != null && selectedModels.some((model) => model?.metadata?.customImage), [selectedModels]);

    const isModelStyledByAttributes = layerStyle.model.extractionMethod === StyleValueExtractionMethod.Mapped;

    const handleSetDimensions = (values: { height: StyleValue<number>; length: StyleValue<number>; width: StyleValue<number>; model: StyleValue<string> }) => {
        if (values.model != null) {
            changeStyleValue("model", values.model);
        }
        changeStyleValue("height", values.height);
        changeStyleValue("length", values.length);
        changeStyleValue("width", values.width);
        setAttributeBasedModal("hidden");
    };

    const handleRemoveAttributeBasedStyling = (model: boolean) => {
        if (model) {
            changeStyleValue("model", { ...layerStyle.model, mappedValues: {}, dataFieldId: null, extractionMethod: StyleValueExtractionMethod.Static });
        }
        changeStyleValue("height", { ...layerStyle.height, mappedValues: {}, dataFieldId: null, extractionMethod: StyleValueExtractionMethod.Static });
        changeStyleValue("length", { ...layerStyle.length, mappedValues: {}, dataFieldId: null, extractionMethod: StyleValueExtractionMethod.Static });
        changeStyleValue("width", { ...layerStyle.width, mappedValues: {}, dataFieldId: null, extractionMethod: StyleValueExtractionMethod.Static });
    };

    const handleResetClick = (property: "height" | "length" | "width") => {
        changeStyleValue(property, createStaticStyleValue(selectedModels[0][property]));
    };

    const getModel = async (modelId: string) => {
        const models = await getModels();
        return models.find((model) => modelId === model.thumbnailAssetId);
    };

    return (
        <>
            <StyleContainer title={<TitleWithIcon title={translate(Content.map2.styles2.model)} icon={["fas", "cube"]} />} testId="model">
                <StyledStyleItemContainer>
                    {selectedModels != null ? (
                        <EditStyleItemComponent
                            styleProperty="model"
                            // For now, we must create a dummy static value until we introduce data driven styling to models
                            value={createStaticStyleValue(selectedModels[0].thumbnailAssetId)}
                            changeStyleValue={changeModelThumbnail}
                            component={modelSelector({
                                assetType: AssetType.Model,
                                selectorDescription: translate(Content.map2.styles2.choose_model),
                                allowedAssetIds: thumbnailAssetIds,
                                imageUrlGetter: (asset) => imageGetter(asset.assetUrl, asset.authoritySignature),
                                assetService,
                                getModel,
                                onAssetRefresh,
                                customImageOnModelValidator: validateModelCanHaveCustomImage,
                            })}
                            title={translate(Content.map2.styles2.type)}
                            dataFields={dataFields}
                            showDataDriven={!isModelStyledByAttributes}
                            onShowFormula={() => setAttributeBasedModal("model")}
                        />
                    ) : (
                        <LoadingComponent />
                    )}
                    {!isModelVariableSize && (
                        <EditStyleItemComponent
                            styleProperty="scale"
                            value={layerStyle.scale}
                            changeStyleValue={changeStyleValue}
                            component={incrementalValueSelectorComponentCreator({
                                minValue: 0,
                                maxValue: 10,
                                increment: 0.1,
                                units: [{ id: "times", name: "" }],
                                selectedUnitId: "times",
                                decimals: 2,
                                dataCy: "scale",
                            })}
                            title={translate(Content.map2.styles2.scale)}
                            isZoomableValue={false}
                        />
                    )}
                    {children}
                </StyledStyleItemContainer>
            </StyleContainer>
            {isModelVariableSize && !isModelStyledByAttributes && (
                <>
                    <StyleContainer title={<TitleWithIcon title={translate(Content.map5.styles.dimensions)} icon={["far", "ruler"]} />}>
                        {layerStyle?.height.dataFieldId == null ? (
                            <DimensionContainer>
                                <EditStyleItemComponent
                                    styleProperty="length"
                                    value={layerStyle.length}
                                    changeStyleValue={changeStyleValue}
                                    component={incrementalValueSelectorComponentCreator(variableSizeProps)}
                                    title={
                                        <DimensionTitleComponent
                                            onClick={() => handleResetClick("length")}
                                            label={translate(Content.map5.fixed_shape.length)}
                                            tooltip={translate(Content.map6.styles.models.resetLength)}
                                        />
                                    }
                                    isZoomableValue={false}
                                />
                                <EditStyleItemComponent
                                    styleProperty="width"
                                    value={layerStyle.width}
                                    changeStyleValue={changeStyleValue}
                                    component={incrementalValueSelectorComponentCreator(variableSizeProps)}
                                    title={
                                        <DimensionTitleComponent
                                            onClick={() => handleResetClick("width")}
                                            label={translate(Content.map5.fixed_shape.width)}
                                            tooltip={translate(Content.map6.styles.models.resetWidth)}
                                        />
                                    }
                                    isZoomableValue={false}
                                />
                                <EditStyleItemComponent
                                    styleProperty="height"
                                    value={layerStyle.height}
                                    changeStyleValue={changeStyleValue}
                                    component={incrementalValueSelectorComponentCreator(variableSizeProps)}
                                    title={
                                        <DimensionTitleComponent
                                            onClick={() => handleResetClick("height")}
                                            label={translate(Content.map2.styles.height)}
                                            tooltip={translate(Content.map6.styles.models.resetHeight)}
                                        />
                                    }
                                    isZoomableValue={false}
                                    dataFields={dataFields}
                                    showDataDriven
                                    onShowFormula={() => setAttributeBasedModal("dimensions")}
                                />
                            </DimensionContainer>
                        ) : (
                            <FormulaSummaryContainer>
                                <FormulaSummaryComponent
                                    dataFields={dataFields}
                                    removeFormula={() => handleRemoveAttributeBasedStyling(false)}
                                    setFormulaDialogOpen={() => setAttributeBasedModal("dimensions")}
                                    label={translate(Content.map5.styles.widthLengthHeight)}
                                />
                            </FormulaSummaryContainer>
                        )}
                    </StyleContainer>
                </>
            )}
            {isModelUsingCustomImage && (
                <StyleContainer
                    title={
                        functionality?.imagesOnModels ? (
                            <TitleWithIcon title={translate(Content.map8.styles.customImage)} icon={["fas", "image"]} />
                        ) : (
                            <>
                                <InteractiveElement
                                    className="upgrade-button"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        functionality.open?.("imagesOnModels");
                                    }}
                                >
                                    <FontAwesomeIcon icon={["far", "circle-up"]} color={theme.primaryColors.focus} className="icon" />
                                </InteractiveElement>
                                {translate(Content.map8.styles.customImage)}
                            </>
                        )
                    }
                    defaultOpen={functionality?.imagesOnModels}
                    testId="custom-image"
                >
                    <CustomImageContainer>
                        <EditStyleItemComponent
                            styleProperty="customImage"
                            value={layerStyle.customImage}
                            changeStyleValue={changeStyleValue}
                            component={imageInputComponentCreator({
                                assetServices: assetService,
                                imageUrlGetter: (asset) => imageGetter(asset.assetUrl, asset.authoritySignature),
                                topText: translate(Content.map8.styles.customImagePerModel),
                                title: translate(Content.map8.styles.selectImage),
                                aspectRatioLimit:
                                    selectedModels.length === 1 && !isModelVariableSize
                                        ? { width: selectedModels[0].imageAspectRatioWidth, height: selectedModels[0].imageAspectRatioHeight }
                                        : undefined,
                            })}
                            title={translate(Content.map8.styles.customImage)}
                            isZoomableValue={false}
                            disabled={!functionality?.imagesOnModels}
                        />
                    </CustomImageContainer>
                </StyleContainer>
            )}
            {attributeBasedModal !== "hidden" && selectedModels != null && (
                <ModelAttributeStyles
                    dataFields={dataFields}
                    onClose={() => setAttributeBasedModal("hidden")}
                    onConfirm={handleSetDimensions}
                    style={layerStyle}
                    selectedDataFieldId={layerStyle?.height?.dataFieldId}
                    type={attributeBasedModal}
                    modelsWithThumbnails={selectedModels}
                    getModel={getModel}
                />
            )}
        </>
    );
};

const DimensionTitleComponent = ({ onClick, label, tooltip }: { onClick: () => void; label: string; tooltip: string }) => {
    const translate = useIventisTranslate();
    return (
        <div className="dimension-header">
            <span>{`${label} (${translate(Content.map3.units.meters)})`}</span>{" "}
            <CursorTooltip text={tooltip}>
                <InteractiveElement className="icon-button" onClick={onClick}>
                    <FontAwesomeIcon icon={["far", "arrows-rotate"]} />
                </InteractiveElement>
            </CursorTooltip>
        </div>
    );
};

const FormulaSummaryContainer = styled.div`
    width: 50%;
`;

const DimensionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    .data-driven-button-container {
        justify-content: flex-start !important;
        .data-driven-button {
            margin-left: 25px;
        }
    }

    .dimension-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: ${incrementalInputWidth};
    }

    .icon-button {
        width: ${smallIconButtonSize};
        height: ${smallIconButtonSize};
        border-radius: ${borderRadius.circle};
        :hover {
            background-color: ${({ theme }: { theme: Theme }) => theme.shades.four};
        }
    }
`;

const CustomImageContainer = styled.div`
    padding-bottom: 15px;
`;

export default ModelEditStyleComponent;
