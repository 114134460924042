/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { Model } from "@iventis/domain-model/model/model";
import {
    defaultLineStyle,
    defaultAreaStyle,
    defaultPointStyle,
    defaultIconStyle,
    defaultModelStyle,
    defaultLineModelStyle,
} from "@iventis/map-engine/src/utilities/default-style-values";
import { createStaticStyleValue, getStaticStyleValue } from "@iventis/layer-style-helpers";
import { getDefaultAssetByType, getDefaultModelId } from "@iventis/components";
import { QueryClient } from "@tanstack/react-query";
import { Asset } from "@iventis/domain-model/model/asset";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { FilterFormat } from "@iventis/types/api.types";
import { DataField } from "@iventis/domain-model/model/dataField";
import { replaceDatafieldAndListItemIdsInStyle } from "@iventis/utilities";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { v4 as uuid } from "uuid";
import { SystemDataFieldName } from "@iventis/domain-model/model/systemDataFieldName";
import { UnionOfStyles } from "@iventis/map-engine";
import { getLayerStyle, setLayerStyle } from "@iventis/map-engine/src/utilities/layer.helpers";
import { getDefaultStyle, styleTypeToLayerProperties } from "@iventis/map-engine/src/utilities/style-helpers";
import { getRequiredDataFieldsForLayer } from "@iventis/datafields";

/**
 * A function to get a default style for a given style type
 */
export const getStyleDefaultValue = async (
    styleType: StyleType,
    getModels: () => Promise<Model[]>,
    getAssetsByType: (pageNumber: number, assetType: AssetType, filter: FilterFormat[]) => Promise<PagedResult<Asset[]>>,
    queryClient: QueryClient
): Promise<UnionOfStyles> => {
    switch (styleType) {
        case StyleType.Line: {
            const defaultLineModel = await getDefaultModelId(getModels);
            return { ...defaultLineStyle, model: defaultLineModel == null ? null : createStaticStyleValue(defaultLineModel) } as LineStyle;
        }
        case StyleType.Area: {
            const defaultSimulationModel = await getDefaultModelId(getModels);
            return { ...defaultAreaStyle, simulationModel: defaultSimulationModel == null ? null : createStaticStyleValue(defaultSimulationModel) } as AreaStyle;
        }
        case StyleType.Point:
            return defaultPointStyle;
        case StyleType.Icon: {
            const defaultIconImage = await getDefaultAssetByType(getAssetsByType, AssetType.MapIcon, queryClient);
            return { ...defaultIconStyle, iconImage: createStaticStyleValue(defaultIconImage) } as IconStyle;
        }
        case StyleType.Model: {
            const defaultModel = await getDefaultModelId(getModels);
            return { ...defaultModelStyle, model: createStaticStyleValue(defaultModel) } as ModelStyle;
        }
        case StyleType.LineModel: {
            const defaultModel = await getDefaultModelId(getModels);
            return { ...defaultLineModelStyle, model: createStaticStyleValue(defaultModel) } as LineModelStyle;
        }
        default:
            throw new Error(`No default style exists for ${styleType}. Go to edit-layers-helpers.ts to create one`);
    }
};

/** Ensures the layer has the required datafields, if the layer does not then add them */
export const addRequiredDataFieldsAndOrder = (
    layerDataFields: DataField[] = [],
    projectDataFields: DataField[],
    styleType: StyleType,
    setLayer: (layer: { dataFields: DataField[] }) => void
) => {
    // Add missing datafields
    const requiredDataFields = getRequiredDataFieldsForLayer(projectDataFields ?? [], styleType).sort((a, b) =>
        a.systemDataFieldName === SystemDataFieldName.MapObjectName
            ? -1
            : b.systemDataFieldName === SystemDataFieldName.MapObjectName
            ? 1
            : (a.systemDataFieldName ?? "").localeCompare(b.systemDataFieldName ?? "")
    );
    const missingDataFields = requiredDataFields.filter(({ id }) => !layerDataFields.some((df) => id === df.id));
    // If layer has an order, append any missing to the end
    if (layerDataFields.every((df) => df.order != null) && layerDataFields.length > 0) {
        const orderOfLast = layerDataFields.length;
        setLayer({ dataFields: [...layerDataFields, ...missingDataFields.map((df, i) => ({ ...df, order: orderOfLast + i + 1 }))] });
        return;
    }
    // Otherwise create a new order
    const allDataFields = [...layerDataFields, ...missingDataFields];
    // Find the name df and place it at the front
    const nameDf = allDataFields.filter((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectName)[0];
    allDataFields.unshift(allDataFields.splice(allDataFields.indexOf(nameDf), 1)[0]);

    // Add an order to the whole list
    const dataFieldsWithOrder = allDataFields.map((df, i) => ({ ...df, order: i + 1, defaultValue: df.defaultValue ?? undefined }));

    setLayer({ dataFields: dataFieldsWithOrder });
};

/** Replace all datafield and list item ids with new ids */
export const getReplacementDataFieldAndListItemIds = (
    dataFields: DataField[],
    projectDataFields: DataField[]
): { replacementDataFieldIds: Map<string, string>; replacementListItemIds: Map<string, string> } => {
    const replacementDataFieldIds = new Map<string, string>();
    const replacementListItemIds = new Map<string, string>();
    const replacementListItemPropertyIds = new Map<string, string>();
    const replacementListItemRelationShipIds = new Map<string, string>();

    const getProjectDataField = (df: DataField) =>
        // Check for predefined project data fields
        projectDataFields.find(({ externalReference }) => externalReference === df.id) ??
        // Check if ids match
        projectDataFields.find(({ id }) => id === df.id) ??
        // Check for system project data fields
        projectDataFields.find(({ systemDataFieldName }) => systemDataFieldName != null && systemDataFieldName === df.systemDataFieldName);

    // Generate new datafield and list item ids and map them first
    dataFields.forEach((df) => {
        const projectDataField = getProjectDataField(df);
        // Replace the datafield with an new id
        const newDataFieldId = projectDataField?.id ?? uuid();
        replacementDataFieldIds.set(df.id, newDataFieldId);
        df.id = newDataFieldId;

        // Generate new list item ids
        df.listValues?.forEach((listItem) => {
            const newListItemId = projectDataField?.listValues.find(({ externalReference, id }) => externalReference === listItem.id || id === listItem.id)?.id ?? uuid();
            replacementListItemIds.set(listItem.id, newListItemId);
            listItem.id = newListItemId;
        });
    });

    dataFields.forEach((df) => {
        const projectDataField = getProjectDataField(df);
        // If list items exists then replace the property ids and the list item ids with new ones
        if (df.type === DataFieldType.List) {
            df.listItemProperties?.forEach((listItemProperty) => {
                // Due to time constraints, we have decided not to add "externalReference" property on a list item property.
                // In future, we may need to find by "externalReference" when we allow layer templates to use the list item property feature
                const newListItemPropertyId = projectDataField?.listItemProperties.find(({ id }) => id === listItemProperty.id)?.id ?? uuid();
                replacementListItemPropertyIds.set(listItemProperty.id, newListItemPropertyId);
                listItemProperty.id = newListItemPropertyId;
            });
            df.listItemRelationships?.forEach((relationship) => {
                // Generate a new relationship if its not from a project datafield
                const newRelationshipId = projectDataField?.listItemRelationships.find(({ id }) => id === relationship.id)?.id ?? uuid();
                replacementListItemRelationShipIds.set(relationship.id, newRelationshipId);
                relationship.id = newRelationshipId;
                relationship.dataFieldId = df.id;
                relationship.relatedToDataFieldId = replacementDataFieldIds.get(relationship.relatedToDataFieldId);
            });
            df.listValues?.forEach((listItem) => {
                listItem.dataFieldId = replacementDataFieldIds.get(listItem.dataFieldId);
                listItem.propertyValues?.forEach((property) => {
                    property.propertyId = replacementListItemPropertyIds.get(property.propertyId);
                    property.dataFieldListItemId = replacementListItemIds.get(property.dataFieldListItemId);
                });
                listItem.relationshipValues?.forEach((relVal) => {
                    relVal.dataFieldListItemId = listItem.id;
                    relVal.relatedToDataFieldListItemId = replacementListItemIds.get(relVal.relatedToDataFieldListItemId);
                    relVal.relationshipId = replacementListItemRelationShipIds.get(relVal.relationshipId);
                });

                // If it was null for whatever reason, set them to empty arrays
                listItem.propertyValues = listItem.propertyValues ?? [];
                listItem.relationshipValues = listItem.relationshipValues ?? [];

                listItem.uniqueId = replacementListItemIds.get(listItem.uniqueId) ?? listItem.uniqueId;
            });

            // If it was null for whatever reason, set them to empty arrays
            df.listItemProperties = df.listItemProperties ?? [];
            df.listItemRelationships = df.listItemRelationships ?? [];
        }

        // If the datafield has a default list item Id then swap the id here too
        if (df.defaultValue?.listItemId != null) {
            df.defaultValue.listItemId = replacementListItemIds.get(df.defaultValue?.listItemId);
        }

        // If the datafield has a non-null default value, its internal default value id should also be updated
        if (df.defaultValue?.dataFieldId != null) {
            df.defaultValue.dataFieldId = df.id;
        }
    });
    return { replacementDataFieldIds, replacementListItemIds };
};

/** Replace the datafield and list item ids with new ids and then replace them in the style */
export const swapTemplateLayerDatafields = (layer: MapLayer, projectDataFields: DataField[]) => {
    if (layer.dataFields == null) {
        return { ...layer, dataFields: [] };
    }

    const { replacementDataFieldIds, replacementListItemIds } = getReplacementDataFieldAndListItemIds(layer.dataFields, projectDataFields);
    return replaceDatafieldAndListItemIdsInStyle(layer, replacementDataFieldIds, replacementListItemIds, getLayerStyle);
};

/**
 * Ensures that a layer has all of the style properties for it's style type
 * Will compare the layer passed into the function with default style of that layer, if a property is missing the default style property value will be used
 * @returns a layer with all style properties for it's style type
 */
export const ensureLayerHasAllStyleProperties = (layer: MapLayer) => {
    const style = getLayerStyle(layer);
    const defaultStyle = getDefaultStyle(layer.styleType);
    const updatedStyle = Object.keys(defaultStyle).reduce((newStyle, stylePropertyKey) => {
        const styleValue = style?.[stylePropertyKey];
        if (styleValue == null) {
            return { ...newStyle, [stylePropertyKey]: defaultStyle[stylePropertyKey] };
        }
        return { ...newStyle, [stylePropertyKey]: styleValue };
    }, style);
    return setLayerStyle(layer, updatedStyle);
};

/** Replaces a layer datafield with the project datafield if it is present */
export const replaceLayerDataFieldsWithProjectDataFields = (layerDataFields: DataField[], projectDataFields: DataField[]): DataField[] => {
    // Create key-value pair so we don't have to keep searching through projectDataFields for each layer datafield
    const projectDataFieldMap: { [dataFieldId: string]: DataField } = {};
    projectDataFields?.forEach((df) => {
        projectDataFieldMap[df?.externalReference ?? df.id] = df;
    });

    // For each layer datafield check if there is a project datafield
    return layerDataFields?.map((datafield) => {
        const projectDataField = projectDataFieldMap[datafield.id];
        return { ...(projectDataField ?? datafield), order: datafield.order };
    });
};

type SideEffect = { [key: string]: StyleValue<unknown> };

export const createLayerStyleChangesSideEffects = (styleType: StyleType, property: string, value: StyleValue<unknown>) => {
    const styleTypeKey = styleTypeToLayerProperties[styleType];
    const sideEffectPropertyChanges: SideEffect = {};

    if (styleTypeKey === "areaStyle" && property === "dimension") {
        sideEffectPropertyChanges.fill = createStaticStyleValue(true);
        // When the dimension changes, disable outline and text, as these are not currently configurable on 3D areas
        sideEffectPropertyChanges.outline = createStaticStyleValue(false);
        sideEffectPropertyChanges.text = createStaticStyleValue(false);
    }

    // When iconTextFit is enabled we want to set size of icon to original size
    if (styleTypeKey === "iconStyle" && property === "iconTextFit" && getStaticStyleValue(value)) {
        sideEffectPropertyChanges.size = createStaticStyleValue(1);
    }

    if (styleTypeKey === "iconStyle" && property === "text" && !getStaticStyleValue(value)) {
        sideEffectPropertyChanges.iconTextFit = createStaticStyleValue(false);
    }

    return sideEffectPropertyChanges;
};

export const hasCategoriesChanged = (old: string[], latest: string[]) => latest.length !== old.length || latest.some((c, i) => c !== old[i]);

export const hasLayerChanged = (oldLayer: MapLayer, newLayer: MapLayer, oldCategories: string[], newCategories: string[], oldImageUrl: string, newImageUrl: string) => {
    // Remove order from the data fields as this is handled seperately
    const oldLayerParsed: MapLayer = removeUnrequiredComparisonProperties(oldLayer);
    const newLayerParsed: MapLayer = removeUnrequiredComparisonProperties(newLayer);

    const isDifferent =
        hasCategoriesChanged(oldCategories, newCategories) ||
        newLayerParsed.name !== oldLayerParsed.name ||
        JSON.stringify(getLayerStyle(newLayerParsed)) !== JSON.stringify(getLayerStyle(oldLayerParsed)) ||
        JSON.stringify(oldLayerParsed.dataFields) !== JSON.stringify(newLayerParsed.dataFields) ||
        newImageUrl !== oldImageUrl;
    return isDifferent;
};

const removeUnrequiredComparisonProperties = (layer: MapLayer): MapLayer => ({ ...layer, dataFields: layer.dataFields.map((df) => ({ ...df, order: null, defaultValue: null })) });
