import { v4 as uuid } from "uuid";
import { parseDataFieldValueToKeyValuePair, getQuantityOfModelsOnALine, DataFieldValueV2Extended } from "@iventis/datafields";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapImportLayer } from "./map-json-types";
import { removeTimeZoneFromDate } from "./map-json-helpers";

export function replaceLayerMapObjectIds(
    layer: MapImportLayer,
    updatedDataFieldIds: { [id: string]: string },
    updatedListItemIds: { [id: string]: string }
): MapImportLayer["mapObjects"] {
    return layer.mapObjects?.map((mapObject) => ({
        ...mapObject,
        dataFieldValues: mapObject.dataFieldValues.map((dfv) => ({
            ...dfv,
            valueDate: removeTimeZoneFromDate(dfv),
            valueRepeatedTimeRanges: dfv?.valueRepeatedTimeRanges?.map((vtr) => ({ ...vtr, id: uuid() })),
            dataFieldId: updatedDataFieldIds[dfv.dataFieldId],
            listItemId: updatedListItemIds[dfv.listItemId],
        })),
        id: uuid(),
        layerId: layer.id,
    }));
}

/** Sets the layers calculated datafield values (currently support quantity) */
export function setLayerCalculatedDataFieldValues(layer: MapImportLayer, quantityDataFieldId: string): MapImportLayer["mapObjects"] {
    switch (layer.styleType) {
        case StyleType.LineModel:
            // For each map object calculate the quantity of models on the line
            return layer.mapObjects?.map((mapObject) => {
                const featureProperties = parseDataFieldValueToKeyValuePair(mapObject.dataFieldValues);
                const quantityDataFieldValue: DataFieldValueV2Extended = {
                    dataFieldId: quantityDataFieldId,
                    valueNumber: getQuantityOfModelsOnALine(
                        { type: "Feature", geometry: { type: "LineString", coordinates: mapObject.coordinates }, properties: featureProperties },
                        layer.style
                    ),
                };
                return {
                    ...mapObject,
                    dataFieldValues: [...mapObject.dataFieldValues.filter((dfv) => dfv.dataFieldId !== quantityDataFieldId), quantityDataFieldValue],
                };
            });
        default:
            return layer.mapObjects;
    }
}
